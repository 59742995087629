import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    components: {
        Checkbox: {
            baseStyle: {
                container: {
                    touchAction: 'none',
                }
            }
        }
    },
    initialColorMode: "light",
    useSystemColorMode: true,
    fonts: {
        heading: `'Ubuntu', sans`,
        body: `'Ubuntu', sans`,
    }
})


export default theme