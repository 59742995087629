import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import bgvid from "./videos/orogen.mp4";

function App() {

    const videoRef = useRef(null);
    const [percentage, setPercentage] = useState(1);

    useEffect(() => {

        document.title = "Orogen Studios";

        const handleResize = () => {
            const basePercent = 100;
            const { innerWidth: width, innerHeight: height } = window;
            if (width >= height) {
                setPercentage(`${basePercent}vw`);
            } else {
                setPercentage(`${basePercent}vh`);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <>
        <Flex position="fixed" top="0" left="0" w="100vw" h="100vh" overflow="hidden" justifyContent="center" alignItems="center">
            <Box position="absolute" w={`${percentage}`}>
                <video autoPlay muted loop playsInline ref={videoRef} style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}>
                    <source src={bgvid} type="video/mp4" />
                </video>
            </Box>
            <Flex position="fixed" bottom="12px" left="12px"><Text fontSize={["small", "medium"]}>Copyright © 2024 Orogen Studios LLC. All Rights Reserved. </Text></Flex>
        </Flex>
        
    </>;
}

export default App;
